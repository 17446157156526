.card-body {
  padding: .75rem !important;
}
.card-content {
  font-size: smaller;
}
.card-date {
  font-size: x-small;
  font-style: italic;
  float: right;
  margin-bottom: -5px;
  margin-top: 5px;
}