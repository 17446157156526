body{
  background-image: url("/image/bg.png");
}

@keyframes blinker {
  50% { opacity: 0; }
}

.blink {
  animation: blinker 0.9s linear infinite;
}
.pointer {
  cursor: pointer !important;
}

.colorWhite { color: #ffffff !important; }
.colorRed { color: #ff0000 !important; }
.colorBlue1 { color: #3f51b5 !important; }
.colorError { color: #d96b1d !important; }
.colorCBlue { color: #2694d9 !important; }

.fontSize5 { font-size: 5px !important; }
.fontSize10 { font-size: 10px !important; }
.fontSize15 { font-size: 15px !important; }
.fontSize20 { font-size: 20px !important; }
.fontSize25 { font-size: 25px !important; }
.fontSize30 { font-size: 30px !important; }
.fontSize35 { font-size: 35px !important; }

.marginTop5 { margin-top: 5px !important; }
.marginTop10 { margin-top: 10px !important; }
.marginTop15 { margin-top: 15px !important; }
.marginTop20 { margin-top: 20px !important; }
.marginTop25 { margin-top: 25px !important; }
.marginTop30 { margin-top: 30px !important; }
.marginTop35 { margin-top: 35px !important; }

.paddingCorner5 { padding: 0 5px !important; }
.paddingCorner10 { padding: 0 10px !important; }
.paddingCorner15 { padding: 0 15px !important; }
.paddingCorner20 { padding: 0 20px !important; }
.paddingCorner25 { padding: 0 25px !important; }
.paddingCorner30 { padding: 0 30px !important; }
.paddingCorner35 { padding: 0 35px !important; }

.paddingCover5 { padding: 5px 0 !important; }
.paddingCover10 { padding: 10px 0 !important; }
.paddingCover15 { padding: 15px 0 !important; }
.paddingCover20 { padding: 20px 0 !important; }
.paddingCover25 { padding: 25px 0 !important; }
.paddingCover30 { padding: 30px 0 !important; }
.paddingCover35 { padding: 35px 0 !important; }

.padding5 { padding: 5px !important; }
.padding10 { padding: 10px !important; }
.padding15 { padding: 15px !important; }
.padding20 { padding: 20px !important; }
.padding25 { padding: 25px !important; }
.padding30 { padding: 30px !important; }
.padding35 { padding: 35px !important; }